export const COLORS = {
  lightWhite: "#FBFDFF",
  lightGray: "#F3F5F6",
  mediumGray: "#B0B8BA",
  gray: "#A0A4A6",
  babyBlue: "#50CAF9",
  blue: "#5368f1",
  purple1: "#CB4DFF",
  purple2: "#8853F1",
}

export const GRADIENT = `linear-gradient(to top right, ${COLORS.purple1}, ${
  COLORS.purple2
})`

export const BORDER_RADIUS = "10px"
